<template>
  <div class="main">
    <banner-swiper class="other">
      <swiper-slide>
        <div class="banner-cover" />
        <div class="banner-desc other">
          <div class="icon" />
          <div class="title">小程序定制服务</div>
          <div class="text">您的需求就是我们的行动纲领<br>资深团队时刻准备为您量身定做最适合的小程序</div>
        </div>
      </swiper-slide>
      <div slot="after" class="english-run">FEYSOS</div>
    </banner-swiper>
    <div class="process wrap">
      <div v-scroll-toggle="['enter']" class="chunk-head">
        <div>CUSTOMIZATION PROCESS</div>
        <div>专业定制流程</div>
      </div>
      <div class="chunk-main">
        <template v-for="(item, index) in processData">
          <div :key="'item_' + index" v-scroll-toggle="['enter']" class="item">
            <div class="icon">
              <img src="@/assets/process_icon.png" alt="">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="label">{{ item }}</div>
          </div>
          <div :key="'cut_' + index" class="cut" />
        </template>
      </div>
    </div>
    <div id="case" class="case">
      <div class="bg" />
      <div class="wrap">
        <div v-scroll-toggle="['enter']" class="chunk-head">
          <div>List of cases</div>
          <div>案例一览</div>
        </div>
        <view-swiper ref="viewSwiper" :options="caseOptions.swiper" class="chunk-main">
          <div slot="before" :class="caseOptions.itemBg.class" :style="caseOptions.itemBg.style" class="item-bg" />
          <swiper-slide v-for="(item, index) in caseOptions.data" :key="index" v-scroll-toggle="['enter']">
            <div @click="$refs.album.open(item)">
              <div class="date">{{ item.date }}</div>
              <div class="title">{{ item.title }}</div>
              <p class="desc">{{ item.desc }}</p>
              <div class="img-scale">
                <img :src="item.cover" alt="">
              </div>
            </div>
          </swiper-slide>
        </view-swiper>
      </div>
    </div>
    <div class="problem wrap">
      <div v-scroll-toggle="['enter']" class="chunk-head">
        <div>Common problem</div>
        <div>常见问题</div>
      </div>
      <div class="chunk-main">
        <ul>
          <li v-scroll-toggle="['enter']">
            <div class="img-scale">
              <img src="@/assets/problem_cover_01.jpg" alt="">
            </div>
            <div class="desc">
              <div>我需要提供什么素材？</div>
              <p>您不需要提供任何素材，一切都请交给我们~</p>
            </div>
          </li>
          <li v-scroll-toggle="['enter']">
            <div class="img-scale">
              <img src="@/assets/problem_cover_02.jpg" alt="">
            </div>
            <div class="desc">
              <div>“小程序定制”收费标准？</div>
              <p>我们将根据您的具体需求，为您量身定做一整套开发方案，并根据开发难度与时间计算出具体费用。</p>
            </div>
          </li>
          <li v-scroll-toggle="['enter']">
            <div class="img-scale">
              <img src="@/assets/problem_cover_03.jpg" alt="">
            </div>
            <div class="desc">
              <div>定制化风格是怎么样的？</div>
              <p>您可以提出任何您想要的风格，我们将为您提供产品样稿供您挑选，直到打造出您心仪的效果。</p>
            </div>
          </li>
          <li v-scroll-toggle="['enter']">
            <div class="img-scale">
              <img src="@/assets/problem_cover_04.jpg" alt="">
            </div>
            <div class="desc">
              <div>我该如何说明需求？</div>
              <p>不需要复杂的文档，只需要扫描网页底部二维码，剩下的请交给我们~</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <album ref="album" />
  </div>
</template>

<script>
import BannerSwiper from '@/components/bannerSwiper'
import ViewSwiper from '@/components/viewSwiper'
import Album from '@/components/album'

import scrollToggle from '@/directives/scrollToggle'

export default {
  name: 'Miniapp',
  components: {
    BannerSwiper,
    ViewSwiper,
    Album
  },
  directives: {
    scrollToggle
  },
  data() {
    return {
      processData: ['发布需求', '需求评估', 'UI设计', '签订合同', '产品开发', '系统部署', '产品验收', '售后维护'],
      caseOptions: {
        swiper: {
          spaceBetween: 0
        },
        itemBg: {
          class: '',
          style: {}
        },
        data: [
          {
            date: '十月',
            title: '出入库管理系统',
            desc: '为批发零售商定制开发的出入库订单管理系统。通过审核、财务、普通员工的权限分化，完美解决甲方商品出入库统计、在线打印订单、流水财务统计等需求。',
            cover: require('@/assets/case_crkxt/cover.png'),
            data: [
              { title: '系统登录', src: require('@/assets/case_crkxt/1.png') },
              { title: '新建订单', src: require('@/assets/case_crkxt/2.png') },
              { title: '杂项', src: require('@/assets/case_crkxt/3.png') },
              { title: '商品入库', src: require('@/assets/case_crkxt/4.png') },
              { title: '订单管理', src: require('@/assets/case_crkxt/5.png') },
              { title: '完成订单详情', src: require('@/assets/case_crkxt/6.png') },
              { title: '待打印详情', src: require('@/assets/case_crkxt/7.png') },
              { title: '修改密码', src: require('@/assets/case_crkxt/8.png') }
            ]
          },
          {
            date: '七月',
            title: '硬核打卡',
            desc: '极简风格打卡类小程序，用最简洁的画面引导用户聚焦于小程序本身的功能。',
            cover: require('@/assets/case_yhdk/cover.png'),
            code: require('@/assets/case_yhdk/code.jpg'),
            data: [
              { title: '我在坚持', src: require('@/assets/case_yhdk/1.png') },
              { title: '已完成坚持', src: require('@/assets/case_yhdk/2.png') },
              { title: '新建坚持-否', src: require('@/assets/case_yhdk/3.png') },
              { title: '新建坚持-是', src: require('@/assets/case_yhdk/4.png') },
              { title: '坚持详情', src: require('@/assets/case_yhdk/5.png') },
              { title: '商品详情页', src: require('@/assets/case_yhdk/6.png') },
              { title: '羽毛福利', src: require('@/assets/case_yhdk/7.png') },
              { title: '我在坚持-提示', src: require('@/assets/case_yhdk/8.png') }
            ]
          },
          {
            date: '六月',
            title: '奔跑吧勇者',
            desc: '结合微信步数功能的趣味小程序。使用微信步数来和各式可爱的小动物们交朋友吧！',
            cover: require('@/assets/case_ppbyz/cover.png'),
            code: require('@/assets/case_ppbyz/code.jpg'),
            data: [
              { title: '分享海报', src: require('@/assets/case_ppbyz/10.png') },
              { title: '步数', src: require('@/assets/case_ppbyz/1.png') },
              { title: '荒漠绿洲', src: require('@/assets/case_ppbyz/2.png') },
              { title: '葱翠密林', src: require('@/assets/case_ppbyz/3.png') },
              { title: '营地', src: require('@/assets/case_ppbyz/4.png') },
              { title: '和小动物一起奔跑吧', src: require('@/assets/case_ppbyz/5.png') },
              { title: '挑战魔王-开始', src: require('@/assets/case_ppbyz/6.png') },
              { title: '每日任务', src: require('@/assets/case_ppbyz/7.png') },
              { title: '捐爱心', src: require('@/assets/case_ppbyz/8.png') },
              { title: '爱心证书', src: require('@/assets/case_ppbyz/9.png') }
            ]
          },
          {
            date: '三月',
            title: '灵签占卜',
            desc: '占卜类小程序，用唯美的画风带领用户体验充满神秘气息的玄学世界。',
            cover: require('@/assets/case_lqzb/cover.png'),
            code: require('@/assets/case_lqzb/code.jpg'),
            data: [
              { title: '分享海报', src: require('@/assets/case_lqzb/8.jpg') },
              { title: '首页', src: require('@/assets/case_lqzb/1.jpg') },
              { title: '开始摇签', src: require('@/assets/case_lqzb/2.jpg') },
              { title: '广场', src: require('@/assets/case_lqzb/3.jpg') },
              { title: '开始占卜', src: require('@/assets/case_lqzb/4.jpg') },
              { title: '我的', src: require('@/assets/case_lqzb/5.jpg') },
              { title: '每日签到', src: require('@/assets/case_lqzb/6.jpg') },
              { title: '每日签到', src: require('@/assets/case_lqzb/7.jpg') }
            ]
          },
          {
            date: '一月',
            title: '养牛高手',
            desc: '养牛收奶的育成类休闲小程序，一起来养可爱的牛牛吧~',
            cover: require('@/assets/case_ynds/cover.png'),
            code: require('@/assets/case_ynds/code.jpg'),
            data: [
              { title: '养牛场', src: require('@/assets/case_ynds/1.jpg') },
              { title: '好友偷奶', src: require('@/assets/case_ynds/2.jpg') },
              { title: '我的', src: require('@/assets/case_ynds/3.jpg') },
              { title: '福利列表', src: require('@/assets/case_ynds/4.jpg') },
              { title: '商品详情', src: require('@/assets/case_ynds/5.jpg') }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    const self = this
    const view = this.$refs.viewSwiper.$children[0].$el
    const slides = this.$refs.viewSwiper.$children[0].swiper.slides
    this.mouseoverFn = function() {
      const viewRect = view.getBoundingClientRect()
      const slideRect = this.getBoundingClientRect()
      self.caseOptions.itemBg.style = {
        width: slideRect.width + 'px',
        height: slideRect.height + 'px',
        transform: 'translate(' + (slideRect.left - viewRect.left) + 'px,' + (slideRect.top - viewRect.top) + 'px)'
      }
      self.caseOptions.itemBg.class = 'active'
    }
    this.mouseleaveFn = function() {
      self.caseOptions.itemBg.class = ''
    }
    for (let index = 0; index < slides.length; index++) {
      const slide = slides[index]
      slide.addEventListener('mouseover', this.mouseoverFn)
      slide.addEventListener('mouseleave', this.mouseleaveFn)
    }
  },
  beforeDestroy() {
    const slides = this.$refs.viewSwiper.$children[0].swiper.slides
    for (let index = 0; index < slides.length; index++) {
      const slide = slides[index]
      slide.removeEventListener('mouseover', this.mouseoverFn)
      slide.removeEventListener('mouseleave', this.mouseleaveFn)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container{
  .banner-cover{
    transform-origin: 50% 0%;
    background-image: url('../../assets/banner_miniapp.jpg');
  }
}
.process{
  padding: 100px 0 150px;
  .chunk-main{
    display: flex;
    padding: 60px 0;
    justify-content: space-between;
    align-items: center;
    >div{
      visibility: hidden;
      &.enter, &.enter + div{
        visibility: visible;
        &:nth-of-type(4n - 3){
          animation: rotateInDownLeft .6s both;
          .label{
            top: 100%;
          }
        }
        &:nth-of-type(4n - 1){
          animation: rotateInUpLeft .6s both;
          .label{
            bottom: 100%;
          }
        }
        &:nth-of-type(even){
          animation: fadeInRight .6s both;
        }
        @for $index from 1 through 15 {
          &:nth-of-type(#{$index}) {
            $i: $index - 1;
            animation-delay: $i * .1s;
            img{
              animation-delay: $i * .6s;
            }
          }
        }
      }
    }
  }
  .item{
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
  }
  .cut{
    flex-shrink: 1;
    flex-grow: 1;
    height: 3px;
    margin: 0 5px;
    border-radius: 3px;
    background-color: $themeColor;
    &:last-child{
      display: none;
    }
  }
  .icon{
    position: relative;
    width: 90px;
    height: 90px;
    img{
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: processIcon 10s infinite both;
      @keyframes processIcon {
        0%, 80%{
          transform: rotate(0deg);
          box-shadow: 0 0 0 0 $themeBarColor, 0 0 0 0 $themeBarColor inset;
        }
        82%{
          transform: rotate(-10deg);
          box-shadow: 0 0 20px 0 $themeBarColor, 0 0 40px 0 $themeBarColor inset;
        }
        98%{
          transform: rotate(730deg);
          box-shadow: 0 0 20px 0 $themeBarColor, 0 0 40px 0 $themeBarColor inset;
        }
        100%{
          transform: rotate(720deg);
          box-shadow: 0 0 0 0 $themeBarColor, 0 0 0 0 $themeBarColor inset;
        }
      }
    }
    span{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      font-size: 46px;
      color: $themeColor;
    }
  }
  .label{
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    margin: 30px 0;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    white-space: nowrap;
    font-size: 18px;
    color: $themeColor;
  }
  @media screen and (max-width: 900px) {
    .chunk-main{
      flex-direction: column;
      >div{
        &.enter, &.enter + div{
          &:nth-of-type(4n - 3){
            .label{
              left: auto;
              right: 100%;
            }
          }
          &:nth-of-type(4n - 1){
            .label{
              left: 100%;
              right: auto;
            }
          }
          &:nth-of-type(even){
            animation-name: fadeInUp;
          }
        }
      }
    }
    .cut{
      width: 3px;
      height: 30px;
      margin: 5px 0;
    }
    .label{
      top: 0 !important;
      bottom: 0 !important;
      margin: auto 20px;
    }
  }
}
.case{
  position: relative;
  .bg{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    background-image: url('../../assets/banner_advert.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    box-shadow: 0 0 40px 20px rgba($themeBgColor, 1) inset, 0 0 0 1000px rgba($themeBgColor, .9) inset;
  }
  .wrap{
    padding: 80px 0 100px;
  }
  .chunk-main{
    height: 550px;
  }
  .swiper-slide{
    visibility: hidden;
    &.enter{
      visibility: visible;
      animation: fadeInRightBig 1s backwards;
      @for $index from 1 through 4 {
        &:nth-of-type(#{$index}){
          animation-delay: ($index - 1) * .2s;
        }
      }
    }
    >div{
      display: flex;
      box-sizing: border-box;
      padding: 0 20px;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
    }
  }
  .item-bg{
    position: absolute;
    top: 30px;
    left: -30px;
    z-index: 0;
    width: 300px;
    height: 600px;
    border-radius: 10px;
    opacity: 0;
    background-color: $themeBarColor;
    box-shadow: 0 6px 26px 6px rgba($themeBarColor, .5);
    transition: all .3s;
    &.active{
      top: 0;
      left: 0;
      opacity: 1;
    }
  }
  .date{
    display: flex;
    height: 50px;
    border-bottom: 1px solid #ff0000;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $themePlaceholderColor;
  }
  .title{
    margin-top: 30px;
    line-height: 1.25em;
    font-size: 32px;
    color: $themeColorActive;
    @include text-overflow(1);
  }
  .desc{
    margin-top: 20px;
    max-width: 100%;
    height: 180px;
    line-height: 30px;
    text-align: justify;
    font-size: 18px;
    color: $themeColor;
    @include text-overflow(6);
  }
  .img-scale{
    margin-top: 20px;
    border-radius: 10px;
    padding-top: 400 / 640 * 100%;
  }
  @media screen and (max-width: 1200px) {
    .title{
      font-size: 24px;
    }
    .desc{
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1000px) {
    .desc{
      height: 192px;
      line-height: 24px;
      @include text-overflow(8);
    }
  }
  @media screen and (max-width: 950px) {
    .title{
      font-size: 20px;
    }
    .desc{
      font-size: 14px;
    }
  }
  @media screen and (max-width: 800px) {
    .desc{
      height: 144px;
      line-height: 24px;
      @include text-overflow(6);
    }
  }
}
.problem{
  padding: 100px 0;
  .chunk-main{
    display: flex;
    flex-direction: column;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      position: relative;
      width: 22.8%;
      overflow: hidden;
      visibility: hidden;
      &.enter{
        visibility: visible;
        animation: fadeInUp .6s backwards;
        @for $index from 1 through 15 {
          &:nth-of-type(#{$index}) {
            animation-delay: ($index - 1) * .2s;
          }
        }
      }
      &:before, &:after{
        position: absolute;
        width: 0;
        height: 0;
        z-index: 2;
        box-sizing: border-box;
        pointer-events: none;
        content: '';
      }
      &:before{
        top: 0;
        left: 0;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        transition: border-color 0s 1.2s, width .3s .9s, height .3s .6s;
      }
      &:after{
        right: 0;
        bottom: 0;
        border-bottom: 1px solid transparent;
        border-left: 1px solid transparent;
        transition: border-color 0s .6s, width .3s .3s, height .3s 0s;
      }
      &:hover{
        &:before, &:after{
          width: 100%;
          height: 100%;
          border-color: $themeColor;
        }
        &:before{
          transition: border-color 0s 0s, width .3s 0s, height .3s .3s;
        }
        &:after{
          transition: border-color 0s .6s, width .3s .6s, height .3s .9s;
        }
        .desc{
          margin-top: 0;
          padding-top: 60px;
          transform: translateY(-100%);
        }
      }
    }
    @media screen and (max-width: 1150px) {
      li{
        &:hover{
          .desc{
            padding-top: 40px;
          }
        }
      }
    }
    @media screen and (max-width: 900px) {
      li{
        width: 48%;
        &:nth-of-type(2) ~ li{
          margin-top: 30px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      justify-content: center;
      li{
        width: 80%;
        & + li{
          margin-top: 30px;
        }
      }
    }
  }
  .img-scale{
    z-index: 0;
    padding-top: 80%;
  }
  .desc{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    margin-top: -47px;
    box-sizing: border-box;
    padding: 15px 20px;
    background-color: rgba(#000, .8);
    transition: all .3s;
    div{
      font-size: 18px;
      color: $themeColorActive;
    }
    p{
      margin-top: 20px;
      text-align: justify;
      line-height: 1.5em;
      font-size: 14px;
      color: $themeColor;
    }
    @media screen and (max-width: 1100px) and (min-width: 900px) {
      div{
        font-size: 14px;
      }
      p{
        font-size: 12px;
      }
    }
  }
}
</style>
