<template>
  <div v-show="is" :class="className" class="album-container">
    <button class="close" @click="close">关闭</button>
    <div v-if="listData.url || listData.code" class="share">
      <a target="_blank" :href="listData.url || 'javascript:void(0)'">
        <img src="@/assets/share.png" alt="前往">
      </a>
      <div v-if="listData.code">
        <img :src="listData.code" alt="">
      </div>
    </div>
    <div class="title">
      <div>{{ listData.title }}</div>
      <div>{{ (listData.data[listData.index] || {}).title }}</div>
    </div>
    <div class="big">
      <swiper ref="bigSwiper" :options="bigSwiperOptions">
        <swiper-slide v-for="(item, i) in listData.data" :key="i">
          <div :style="{ 'background-image': 'url(' + item.src + ')' }" />
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="listData.desc.data" :class="listData.desc.show ? 'show' : 'hide'" class="desc" @click="listData.desc.show = !listData.desc.show">{{ listData.desc.data }}</div>
    <div v-if="init" class="small">
      <swiper ref="smallSwiper" :options="smallSwiperOptions">
        <swiper-slide v-for="(item, i) in listData.data" :key="i">
          <div class="img-scale" :style="{ 'background-image': 'url(' + item.src + ')' }" @click="slideTo(i)">
            <p>{{ item.title }}</p>
          </div>
        </swiper-slide>
      </swiper>
      <button class="prev" @click="prev">上一张</button>
      <button class="next" @click="next">下一张</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Album',
  data() {
    const self = this
    return {
      is: false,
      init: true,
      className: '',
      listData: {
        title: '',
        desc: {
          show: false,
          data: ''
        },
        code: '',
        url: '',
        index: 0,
        data: []
      },
      bigSwiperOptions: {
        on: {
          slideChange() {
            self.slideChange()
          }
        }
      },
      smallSwiperOptions: {
        slidesPerView: 8,
        spaceBetween: 10
      }
    }
  },
  mounted() {
    const self = this
    self.resizeFn = function() {
      const column = Math.floor(this.innerWidth / 120)
      if (column !== self.smallSwiperOptions.slidesPerView) {
        self.init = false
        self.smallSwiperOptions.slidesPerView = column
        self.$nextTick(() => {
          self.init = true
        })
      }
    }
    self.resizeFn.call(window)
    window.addEventListener('resize', self.resizeFn)
    document.body.appendChild(self.$el)
  },
  beforeDestroy() {
    this.clear()
    window.removeEventListener('resize', this.resizeFn)
  },
  methods: {
    open(options) {
      document.body.style.overflow = 'hidden'
      this.listData.title = options.title || ''
      this.listData.desc.data = options.desc || ''
      this.listData.data = options.data || []
      this.listData.index = options.index || 0
      this.listData.code = options.code || ''
      this.listData.url = options.url || ''
      this.is = true
      this.className = 'open'
      this.clear()
      this.time = setTimeout(() => {
        this.className = ''
        this.slideChange(this.listData.index)
      }, 600)
      console.log(this.listData)
    },
    close() {
      document.body.style.overflow = ''
      this.className = 'close'
      this.clear()
      this.time = setTimeout(() => {
        this.$refs.bigSwiper.swiper.slideTo(0, 0)
        this.is = false
        this.listData.title = ''
        this.listData.desc = {
          show: false,
          data: ''
        }
        this.listData.index = 0
        this.listData.data = []
      }, 600)
    },
    clear() {
      typeof this.time === 'number' && clearTimeout(this.time)
    },
    prev() {
      this.$refs.bigSwiper.swiper.slidePrev()
    },
    next() {
      this.$refs.bigSwiper.swiper.slideNext()
    },
    slideChange(activeIndex) {
      const bigSwiper = this.$refs.bigSwiper.swiper
      const smallSwiper = this.$refs.smallSwiper.swiper
      const toIndex = activeIndex || bigSwiper.activeIndex
      smallSwiper.slideTo(toIndex)
      if (toIndex !== bigSwiper.activeIndex) this.slideTo(toIndex)
      for (let index = 0; index < smallSwiper.slides.length; index++) {
        index === toIndex ? smallSwiper.slides.eq(index).addClass('active') : smallSwiper.slides.eq(index).removeClass('active')
      }
    },
    slideTo(index) {
      this.listData.index = index
      this.$refs.bigSwiper.swiper.slideTo(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.album-container{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: #000;
  &.open{
    animation: fadeIn .6s backwards;
  }
  &.close{
    animation: fadeOut .6s backwards;
  }
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
    width: 30px;
    height: 30px;
    border: 0 none;
    padding: 0;
    text-indent: -999px;
    background: transparent;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    &:before, &:after{
      position: absolute;
      top: 3px;
      left: 14px;
      width: 2px;
      height: 24px;
      background-color: $themeScrollbarColor;
      content: '';
    }
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
    }
  }
  .share{
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 9;
    a{
      display: block;
      width: 30px;
      height: 30px;
      border: 0 none;
      padding: 0;
      text-indent: -999px;
      background: transparent;
      outline: none;
      img{
        display: block;
        width: 100%;
        height: 100%;
        transform: scale(.8);
      }
    }
    div{
      position: absolute;
      top: 100%;
      right: 100%;
      width: 0;
      height: 0;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0;
      transition: all .3s;
      background-color: $themeBarColor;
      img{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    &:hover{
      div{
        width: 200px;
        height: 200px;
        padding: 10px;
      }
    }
  }
  .title{
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    height: 70px;
    box-sizing: border-box;
    padding: 20px 60px;
    align-items: flex-end;
    >div{
      &:nth-of-type(1){
        font-size: 30px;
        color: $themeColorActive;
      }
      &:nth-of-type(2){
        padding-left: 30px;
        font-size: 20px;
        color: $themeColor;
      }
    }
  }
  .big{
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
    height: 50%;
    margin: 0 60px;
    .swiper-container{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .swiper-slide{
      >div{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .desc{
    flex-shrink: 0;
    flex-grow: 0;
    margin: 15px 60px;
    line-height: 30px;
    font-size: 16px;
    color: $themeColor;
    cursor: pointer;
    &.show{
      max-height: 150px;
      overflow-x: hidden;
      overflow-y: auto;
      @include scrollbar;
    }
    &.hide{
      @include text-overflow(1, 30px);
    }
  }
  .small{
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 15px;
    padding: 0 60px;
    .swiper-slide{
      background-color: $themeBgColor;
      cursor: pointer;
    }
    .img-scale{
      padding-top: 66%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .active{
      &:before{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        box-sizing: border-box;
        border: 3px solid $themeColor;
        content: '';
      }
    }
    .prev, .next{
      position: absolute;
      top: 50%;
      z-index: 9;
      width: 20px;
      height: 60px;
      border: 0 none;
      padding: 0;
      background: transparent;
      text-indent: -999px;
      overflow: hidden;
      outline: none;
      opacity: .6;
      transform: translateY(-50%);
      cursor: pointer;
      &:before, &:after{
        position: absolute;
        width: 4px;
        height: 30px;
        background-color: $themeBarColor;
        content: '';
      }
      &:before{
        bottom: 50%;
      }
      &:after{
        top: 50%;
      }
      &:hover{
        opacity: 1;
      }
    }
    .prev{
      left: 20px;
      &:before, &:after{
        left: 0;
      }
      &:before{
        transform: rotate(30deg);
        transform-origin: left bottom;
      }
      &:after{
        transform: rotate(-30deg);
        transform-origin: left top;
      }
    }
    .next{
      right: 20px;
      &:before, &:after{
        right: 0;
      }
      &:before{
        transform: rotate(-30deg);
        transform-origin: right bottom;
      }
      &:after{
        transform: rotate(30deg);
        transform-origin: right top;
      }
    }
    p{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      line-height: 20px;
      text-align: center;
      padding: 0 5px;
      font-size: 12px;
      color: $themeColorActive;
      background-color: rgba(#000, .5);
      @include text-overflow(1);
    }
  }
  @media screen and (max-width: 800px) {
    .close{
      top: 10px;
      right: 10px;
      transform: scale(.8);
    }
    .share{
      top: 50px;
      right: 10px;
      transform: scale(.8);
    }
    .title{
      height: auto;
      padding: 10px 20px;
      flex-direction: column;
      align-items: center;
      >div{
        &:nth-of-type(1){
          font-size: 18px;
        }
        &:nth-of-type(2){
          margin-top: 5px;
          padding-left: 0;
          font-size: 14px;
        }
      }
    }
    .big{
      margin: 0 20px;
    }
    .desc{
      margin: 10px 20px;
      line-height: 15px;
      font-size: 12px;
    }
    .small{
      margin-bottom: 10px;
      padding: 0 20px;
      .prev, .next{
        display: none;
      }
    }
  }
}
</style>
