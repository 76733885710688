<template>
  <div v-if="init" class="column-container">
    <slot name="before" />
    <swiper :options="swiperOptions" class="swiper">
      <slot />
    </swiper>
    <div v-if="showPagination" class="column-pagination" />
    <slot name="after" />
  </div>
</template>

<script>
export default {
  name: 'ViewSwiper',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      init: true,
      showPagination: true,
      swiperOptions: {}
    }
  },
  created() {
    this.optionsMerge({
      other: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 40
      },
      pagination: {
        el: '.column-pagination',
        clickable: true
      },
      on: {
        init(vm, swiper, swiperOptions) {
          vm.showPagination = Math.ceil(swiper.slides.length / swiperOptions.slidesPerGroup) > 1
        },
        resize(width, done) {
          let column = 4
          if (width < 450) {
            column = 1
          } else if (width < 800) {
            column = 2
          } else {
            column = 4
          }
          done(column)
        }
      }
    }, this.options)
  },
  mounted() {
    const self = this
    this.resizeFn = function() {
      self.resizeCallback.call(this, this.innerWidth, column => {
        if (column !== self.swiperOptions.slidesPerView) {
          self.init = false
          self.swiperOptions.slidesPerView = column
          if (self.viewMerge) self.swiperOptions.slidesPerGroup = column
          self.$nextTick(() => {
            self.init = true
          })
        }
      })
    }
    this.resizeFn.call(window)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  },
  methods: {
    optionsMerge(optionsDefault, optionsMerge) {
      const self = this

      self.viewMerge = typeof optionsMerge.viewMerge === 'boolean' ? optionsMerge.viewMerge : true
      optionsMerge.viewMerge = undefined

      const pagination = Object.assign({}, optionsDefault.pagination, optionsMerge.pagination)
      optionsMerge.pagination = undefined

      self.$on('init', optionsDefault.on.init)
      optionsDefault.on.init = function() {
        self.$emit('init', self, this, self.swiperOptions)
      }

      self.resizeCallback = optionsDefault.on.resize
      optionsDefault.on.resize = undefined

      if (optionsMerge.on) {
        if (optionsMerge.on.resize) {
          self.resizeCallback = optionsMerge.on.resize
          optionsMerge.on.resize = undefined
        }
        if (optionsMerge.on.init) {
          self.$on('init', optionsMerge.on.init)
          optionsMerge.on.init = undefined
        }
      }

      const on = Object.assign({}, optionsDefault.on, optionsMerge.on)
      optionsMerge.on = undefined

      const other = Object.assign({}, optionsDefault.other, optionsMerge)

      self.swiperOptions = {
        ...other,
        pagination,
        on
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.column-container{
  position: relative;
  .swiper{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
  }
  ::v-deep .column-pagination{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    .swiper-pagination-bullet{
      width: 10px;
      height: 10px;
      border-radius: 5px;
      opacity: 1;
      outline: none;
      background: $themeBarColor;
      transition: all .3s;
      & + .swiper-pagination-bullet{
        margin-left: 10px;
      }
      &.swiper-pagination-bullet-active{
        width: 20px;
        background: $themeColorActive;
      }
    }
  }
}
</style>
